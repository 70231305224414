<template>
  <div class="add-container">
      <div class="add-top">
            <div class="top-header">
              <Table class="table"  border :columns="headerColumns" :data="headerData"></Table>
              <!-- <div class="btn-div">
                  <Button type="primary" @click="openCheck">查看修改记录</Button>
              </div>    -->
          </div>
      </div>
      <div class="add-bottom">
          <div class="bottom-menu">
               <Menu theme="light" :active-name="menuName" @on-select="selectMenu">
                   <MenuItem name="1">
                        <Icon type="ios-information-circle" />
                        基本信息
                    </MenuItem>
                    <MenuItem name="2">
                       人员详细信息
                    </MenuItem>
                    <MenuItem name="3">
                    <Icon type="ios-information-circle" />
                       网上家园信息
                    </MenuItem>
                    <!-- <MenuItem name="4">
                       家庭成员信息
                    </MenuItem> -->
                    <MenuItem name="4">
                       党员信息
                    </MenuItem>
                    <MenuItem name="5">
                       志愿者信息
                    </MenuItem>
                    <MenuItem name="6">
                       居民小组信息
                    </MenuItem>
                    <MenuItem name="7">
                       健康需求评估
                    </MenuItem>
                    <MenuItem name="8">
                      特殊困难群体信息采集
                    </MenuItem>
                    <MenuItem name="9">
                      低保申请信息
                    </MenuItem>
                    <MenuItem name="10">
                      孤独老人信息
                    </MenuItem>
                    <MenuItem name="11">
                      残疾人信息
                    </MenuItem>
                    <MenuItem name="12">
                      退役军职信息
                    </MenuItem>
                    <MenuItem name="13">
                      疫情防控档案信息
                    </MenuItem>
               </Menu>
          </div>
          <div class="bottom-container">
              <keep-alive>
                <component :is="showMenu"
                    @getDetail="getBaseDetail"
                    @getPerson="getPerson"
                    @getHome="getHome"
                    @getParty="getParty"
                    @getVolunn="getVolunn"
                    @getResident="getResident"
                    @getHealthy="getHealthy"
                    @getSpecially="getSpecially"
                    @getAllow="getAllow"
                    @getLonely="getLonely"
                    @getDisabled="getDisabled"
                    @getRetire="getRetire"
                    @getVaccination="getVaccination"
                    :archiveHardItemRoList="formData.archiveHardItemRoList"
                    @treeDataList="treeDataList"
                    :checkOther="checkOther"
                    @changeOther="changeOther"
                    @getTreeData="getTreeData"
                    :familyId="$route.query.familyId"   :formData="formData.userHomeDetail" 
                    
                    :formDataPerson="formData.userHomeInfo" 
                    :formDataHome="formData.userHomeArchiveVos" 
                    :orgCode='formData.userHomeDetail.orgCode'
                    :userId="$route.query.userId"
                    :familyMemberId="$route.query.familyMemberId"
                    
                    :formDataParty="formData.archiveCommunistRo"
                    :formDataVolun="formData.archiveVolunteerRo" :formDataResident="formData.userRegionGroupRo" :formDataHealth="formData.userHealthRo"
                    :formDataHard="formData.archiveHardRo" :formDataAllowan="formData.archiveBasiclivingRo" :formDataAllone="formData.archiveLonelyRo"
                    :formDataDisabled="formData.archiveDisabledRo" :formDataRetire="formData.archiveRetirementRo" :archiveVaccinationRo="formData.archiveVaccinationRo" :archiveNatRoList="formData.archiveNatRoList"  :vaccinationDetailRoList="formData.vaccinationDetailRoList"
                    :partyCheck="checkList.partyCheck" :volunCheck="checkList.volunCheck" :residentCheck="checkList.residentCheck" :heathyCheck="checkList.heathyCheck" :specialCheck="checkList.specialCheck" :allowCheck="checkList.allowCheck" :alloneCheck="checkList.alloneCheck"
                    :disabliCheck="checkList.disabliCheck" :retireCheck="checkList.retireCheck"
                    @changeName="changeName"   @changeCheck="changeParty" @changeVolun="changeVolun" @changeResident="changeResident" @changeHealth="changeHealth"  @changeSpecial="changeSpecial" @changeAllow="changeAllow"  @changeAllone="changeAllone"
                     @changeDiable="changeDiable" @changeRetire="changeRetire"
                     :indexoforg="allowOrgCode"
                ></component>
               </keep-alive>
          </div>
          <div class="bottom-right">
              <div class="btn-div">
                  <div>&nbsp;<Button type="primary" @click="changeUp" v-if="menuName != '1'">上一个</Button></div>
                  <div>&nbsp;<Button type="primary" @click="changeDown" v-if="menuName != '13'">下一个</Button></div>
                  <div><Button type="primary" @click="changeClose">关闭</Button></div>
                  <div><Button type="primary" @click="changeSave"  :loading="saveLoading" >保存</Button></div>
              </div>
          </div>
      </div>
      <LiefengModal
        :value="selectModal"
        title="居民信息修改记录"
        @input="selectStatus"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
            <record :isShow="isShow"></record>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="selectCancel">确定</Button>
        </template>
      </LiefengModal>
      <LiefengModal 
        title='用户已经存在'
        :value="openTips"
        @input="changeOpenTips"
        width="500px"
        height="200px"
        >
        <template v-slot:contentarea>
          <p style="text-align:center; margin-top:40px;font-size:16px">{{tipIdNum}}</p>
          <p style="text-align:center;font-size:16px">身份证已经存在</p>
        </template>
        <template v-slot:toolsbar>
             <Button type="primary" style="marginRight:10px"  @click="goLook">前去查看</Button>
            <Button @click="aginText">重新录入身份证</Button>
          </template>
      </LiefengModal>
  </div>
</template>

<script>
import Base from '../form/base'
import Personal from '../form/personal'
import Home from '../form/home'
import Family from '../form/family'
import Party from '../form/party'
import Volunteer from '../form/volunteer'
import Resident from '../form/resident'
import Healthy from '../form/healthy'
import Special from '../form/special'
import Allowance from '../form/allowance'
import Lonely from '../form/lonely'
import Disability from '../form/disability'
import Retire from '../form/retire'
import Vaccines from '../form/vaccines'
import LiefengModal from "@/components/LiefengModal";
import record from '../childrens/record.vue'
 export default {
    components:{Base,Personal,Home,Family,Party,Volunteer,Resident,Healthy,Special,Allowance,Lonely,Disability,Retire,Vaccines,LiefengModal,record},
    data(){
        
        return{
            openTips:false,
            tipIdNum:'',
            headerColumns:[
                {
                    title: "姓名",
                    align: "center",
                    width:200,
                    render:(h,params)=>{
                        return h('div',{},params.row.name == '' ?'暂无' :params.row.name)
                    }
                },
                {
                    title: "党员",
                    align: "center",
                    render:(h,params)=>{
                        return h('div',{},params.row.party == true ?'是':'否' )
                    }
                },
                {
                    title: "志愿者",
                    align: "center",
                     render:(h,params)=>{
                        return h('div',{},params.row.volunteer == true ?'是':'否' )
                    }
                },
                {
                    title: "居民小组人员",
                    align: "center",
                     render:(h,params)=>{
                        return h('div',{},params.row.resident == true ?'是':'否' )
                    }
                },
                {
                    title: "是否进行健康评估",
                    align: "center",
                     render:(h,params)=>{
                        return h('div',{},params.row.healthy == true ?'是':'否' )
                    }
                },
                {
                    title: "困难群体",
                    align: "center",
                     render:(h,params)=>{
                        return h('div',{},params.row.special == true ?'是':'否' )
                    }
                },
                {
                    title: "享受低保",
                    align: "center",
                     render:(h,params)=>{
                        return h('div',{},params.row.allowance == true ?'是':'否' )
                    }
                },
                {
                    title: "孤独老人",
                    align: "center",
                     render:(h,params)=>{
                        return h('div',{},params.row.lonely == true ?'是':'否' )
                    }
                },
                {
                    title: "残疾人士",
                    align: "center",
                     render:(h,params)=>{
                        return h('div',{},params.row.disability == true ?'是':'否' )
                    }
                },
                {
                    title: "退役军人",
                    align: "center",
                     render:(h,params)=>{
                        return h('div',{},params.row.retire == true ?'是':'否' )
                    }
                },
            ],
            headerData:[
                {name:'',party:'',volunteer:'',resident:'',healthy:'',special:'',allowance:'',lonely:'',disability:'',retire:''}
            ],
            menuName:'1',
            showMenu:'Base',
            formData:{
                userHomeDetail:{},//基本信息
                userHomeInfo:{},//人员详细信息
                userHomeArchiveVos:[
                    {
                        addr:'',
                        contact:'2',
                        houseCode:'',
                        userType:'5',
                        status:'2'
                    }
                ],//网上家园信息
                archiveCommunistRo:{},//党员信息
                archiveVolunteerRo:{},//志愿者信息
                userRegionGroupRo:{},//居民小组信息
                userHealthRo:{}, //健康需求评估
                archiveHardRo:{},//特殊群体
                archiveHardItemRoList:[],//特殊群体内选项选择的内容
                archiveBasiclivingRo:{}, //低保申请信息
                archiveLonelyRo:{},//孤独老人
                archiveDisabledRo:{},//残疾人信息
                archiveRetirementRo:{},//退役军人信息
                archiveVaccinationRo:{},//疫苗接种情况
                vaccinationDetailRoList:[{
                        vaccinationDate:'',
                        brand:'',
                        batch:'0'
                    },
                    {
                        vaccinationDate:'',
                        brand:'',
                        batch:'1'
                    },
                    {
                        vaccinationDate:'',
                        brand:'',
                        batch:'2'
                    }],//接种时间
                archiveNatRoList:[{natDate:'',natResult:''}], //核酸检测情况
                // custGlobalId: "string,用户全局ID、新增则不需要传入",
                // familyId: "string,家庭ID",
                // familyMemberId: "string,用户家庭成员ID、新增则不需要传入",
                operCustGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                operCustGlobalName:parent.vue.loginInfo.userinfo.realName
                // orgCode: "string,用户所属的机构代码",
            },
            checkList:{
                partyCheck:false,
                volunCheck:false,
                residentCheck:false,
                heathyCheck:false,
                specialCheck:false,
                allowCheck:false,
                alloneCheck:false,
                disabliCheck:false,
                retireCheck:false,
            },
            treeData:[],
            selectData:[],

            allowOrgCode:'',
            //保存按钮的loading
            saveLoading:false ,
            checkOther:false, //特殊困难群体
            selectModal:false,
            isShow:0,
            addFailed:{
                custGlobalId:'',
                familyId:'',
                familyMemberId:'',
                orgCode:'',
                userId:'',
            }
        }
    },
    mounted(){
        if(this.$route.query.isEdit == 'false' ){
            // 
        }else{
            this.getHeader()
            if((this.$route.query.grid != 'true' || this.$route.query.build != 'true'))
            this.getConcact()
        }
    },
    methods:{
        // 点击弹窗确认按钮
        selectCancel(){
            this.selectModal = false
        },
        // 弹窗关闭事件
        selectStatus(status){
            this.selectModal = status
        },
        // 点击查看修改记录
        openCheck(){
             this.selectModal = true
             ++this.isShow
        },
        getTreeData(val){
            if(this.formData.archiveHardItemRoList && this.formData.archiveHardItemRoList.length != 0){
               let data = []
               this.formData.archiveHardItemRoList.map(item=>{
                   data.push(item.itemId)
               })
               console.log(this.formatterEditItem(val,data.join(',')));  
            }
        },
        changeOther(val){
            this.checkOther = val
        },
        formatterEditItem(val,val2){
            if(val){
                val.map(item=>{
                    if(item.itemCode.length == 3){
                        item.children.map(items=>{
                            if(val2.indexOf(items.itemId) > -1){
                                item.groupItem = items.itemId
                                item.groupScore = items.itemValue
                            }
                        })
                    }else{
                        this.formatterEditItem(item.children,val2)
                    }
                })
            }
            return val
        },
        getConcact(){
          if(this.$route.query.userId && this.$route.query.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectUserFamilyList',{
              userId:this.$route.query.userId,
            //   familyMemberId:this.$route.query.familyMemberId
            }).then(res=>{
              if(res.code == 200){
                  this.formData.userHomeArchiveVos = res.dataList
              }else{
                this.$Message.error({
                  content:'网上家园信息获取失败',
                  background:true
                })
                return
              }
            })
          }
        },
        // 获取树结构
        treeDataList(val){
            this.formData.archiveHardItemRoList = []
            this.scrollList = []
            this.formData.archiveHardRo.score = 0
            this.formatterData(val)
            if(this.scrollList.length != 0){
                 this.scrollList.forEach(item=>{
                    this.formData.archiveHardRo.score = Number(this.formData.archiveHardRo.score) + Number(item)
                })
            }
           if(this.formData.archiveHardRo.score >= 0 && this.formData.archiveHardRo.score <=  50){
               this.formData.archiveHardRo.scoreLevel = '4'
           }else if(this.formData.archiveHardRo.score >= 55 && this.formData.archiveHardRo.score <= 100){
               this.formData.archiveHardRo.scoreLevel = '3'
           }else if(this.formData.archiveHardRo.score >= 105 && this.formData.archiveHardRo.score <= 150){
               this.formData.archiveHardRo.scoreLevel = '2'
           }else if(this.formData.archiveHardRo.score >= 155 && this.formData.archiveHardRo.score <= 200){
               this.formData.archiveHardRo.scoreLevel = '1'
           }
            // console.log('获取的数组',this.formData.archiveHardItemRoList);
            // console.log('获取的分数scrol',this.scrollList);
        },
        formatterData(val){
            val.map(item=>{
                if(item.itemCode.length ==3 && item.itemCode.length !=4){
                    if(item.groupItem != ''){
                        this.formData.archiveHardItemRoList.push({itemId:item.groupItem})
                        item.children.map(items =>{
                            if(item.groupItem == items.itemId){
                                this.scrollList.push(Number(items.itemValue))
                            }
                        })
                    }     
                }else{
                    this.formatterData(item.children)
                }
            })
        },
        // 获取基本信息
        getBaseDetail(val){
            
            this.formData.userHomeDetail = {}
            if(val != null){
                this.formData.userHomeDetail = val
                 this.headerData[0].name = val.name
            }
            
        },
        // 获取人员详细信息
        getPerson(val){
            this.formData.userHomeInfo = {}
            if(val != null){this.formData.userHomeInfo = val}   
        },
        // 获取网上家园
        getHome(val){
            this.formData.userHomeArchiveVos = {}
            if(val != null){this.formData.userHomeArchiveVos = val}
            
        },
        // 获取党员信息
        getParty(val){
             this.formData.archiveCommunistRo = {}
             if(val != null){this.formData.archiveCommunistRo = val}
            
        },
        // 获取志愿者信息
        getVolunn(val){
            this.formData.archiveVolunteerRo = {}
            if(val != null){this.formData.archiveVolunteerRo = val}
        },
        // 居民小组信息
        getResident(val){
             this.formData.userRegionGroupRo = {}
            if(val != null){
                this.formData.userRegionGroupRo = val
            }
           
            
        },
        // 获取健康信息
        getHealthy(val){
            this.formData.userHealthRo = {}
            if(val != null){this.formData.userHealthRo = val}
            
        },
        // 获取特殊困难群体
        getSpecially(val){

            this.formData.archiveHardRo = {}
            if(val.archiveHardVo != null){
                this.formData.archiveHardRo = val.archiveHardVo;
                if(this.formData.archiveHardRo.orgCodeOther && this.formData.archiveHardRo.orgCodeOther != ''){
                    this.checkOther = true
                }else{
                    this.checkOther = false
                }
              
            }
            if(val.archiveHardItemVoList != null)  this.formData.archiveHardItemRoList = val.archiveHardItemVoList
        
        },
        // 低保申请信息
        getAllow(val){
            this.formData.archiveBasiclivingRo = {}
            if(val != null){this.formData.archiveBasiclivingRo = val}
            
        },
        // 孤独老人信息
        getLonely(val){
            this.formData.archiveLonelyRo = {}
            if(val != null){this.formData.archiveLonelyRo = val}
            
        },
        // 残疾人信息
        getDisabled(val){
            this.formData.archiveDisabledRo = {}
            if(val != null){this.formData.archiveDisabledRo = val}
            
        },
        // 退役军人信息
        getRetire(val){
            this.formData.archiveRetirementRo ={}
            if(val != null){this.formData.archiveRetirementRo =val}
            
        },
        // 防疫信息
        getVaccination(val){
            if(val.natVoList && val.natVoList.length != 0){
               this.formData.archiveNatRoList = val.natVoList
                
            }else{
                this.formData.archiveNatRoList=[{natDate:'',natResult:''}]
            }
            if(val.vaccinationDetailVoList && val.vaccinationDetailVoList.length != 0){
                 this.formData.vaccinationDetailRoList = val.vaccinationDetailVoList
                if(val.vaccinationDetailVoList.length == 1){
                    this.formData.vaccinationDetailRoList.push({
                        vaccinationDate:'',
                        brand:'',
                        batch:'1'
                    },{
                        vaccinationDate:'',
                        brand:'',
                        batch:'2'
                    })
                }
                if(val.vaccinationDetailVoList.length == 2){
                    this.formData.vaccinationDetailRoList.push({
                        vaccinationDate:'',
                        brand:'',
                        batch:'2'
                    })
                }
               
            }else{
                this.formData.vaccinationDetailRoList=[{
                        vaccinationDate:'',
                        brand:'',
                        batch:'0'
                    },
                    {
                        vaccinationDate:'',
                        brand:'',
                        batch:'1'
                    },
                    {
                        vaccinationDate:'',
                        brand:'',
                        batch:'2'
                    }]
            }
            if(val.vaccinationVo != null){
                this.formData.archiveVaccinationRo = val.vaccinationVo
            }else{
                this.formData.archiveVaccinationRo = {}
            }
            
        },
        // 党员的
        changeParty(val){
            this.headerData[0].party = val
            this.checkList.partyCheck = val
        },
        // 志愿者的
        changeVolun(val){
            this.headerData[0].volunteer = val
             this.checkList.volunCheck = val
        },
        // 小组信息
        changeResident(val){
            this.headerData[0].resident = val
            this.checkList.residentCheck = val
        },
        // 健康评估
        changeHealth(val){
            this.headerData[0].healthy = val
            this.checkList.heathyCheck = val
        },
        // 特殊困难群体
        changeSpecial(val){
            this.headerData[0].special = val
            this.checkList.specialCheck = val
        },
        // 低保信息
        changeAllow(val){
            this.headerData[0].allowance = val
            this.checkList.allowCheck = val
        },
        // 孤独老人
        changeAllone(val){
            this.headerData[0].lonely = val
            this.checkList.alloneCheck = val
        },
        // 残疾人信息
        changeDiable(val){
             this.headerData[0].disability = val
             this.checkList.disabliCheck = val
        },
        // 退役军职信息
        changeRetire(val){
            this.headerData[0].retire = val
            this.checkList.retireCheck = val
        },
        // 点击了左边菜单事件
        selectMenu(name){
            this.isName(name)
            this.menuName = name     
        },
        // 上一个
        changeUp(){
            console.log(this.menuName);
            this.menuName = String(Number(this.menuName)-1)
            this.isName(this.menuName)
        },
        // 下一个
        changeDown(){
            console.log(this.menuName);
            this.menuName = String(Number(this.menuName)+1)
             this.isName(this.menuName)
        },
        // 关闭
        changeClose(){
            if(this.$route.query.build)
            this.$router.push(`/regionaloverview?isLineHome=${this.$route.query.isLineHome}&communityCode=${this.$route.query.communityCode}&nameover=${this.$route.query.nameover}`)
            else if(this.$route.query.grid)
            this.$router.push(`/gridindex?isLineHome=${this.$route.query.isLineHome}`)
            else
            this.$router.push((`/linehomeindex?isLineHome=${this.$route.query.isLineHome}&page=${this.$route.query.page}`))
        },
        // 保存
        changeSave(){
           let data = this.formData
           //判断基本信息
            if(data.userHomeDetail){
                if(!data.userHomeDetail.name || data.userHomeDetail.name == ''){
                     this.$Message.warning({
                        content:'基本信息-姓名不能为空',
                        background:true
                    })
                    return
                }
                let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if(data.userHomeDetail.mobileMaster && !myreg.test(data.userHomeDetail.mobileMaster)){
                       this.$Message.warning({
                            content:'基本信息-请填写正确的手机号码1',
                            background:true
                        }) 
                        return
                }
                if(data.userHomeDetail.mobileBackup && !myreg.test(data.userHomeDetail.mobileBackup)){
                       this.$Message.warning({
                            content:'基本信息-请填写正确的手机号码2',
                            background:true
                        }) 
                        return
                }
                if(!data.userHomeDetail.idNumType || data.userHomeDetail.idNumType == ''){
                    this.$Message.warning({
                        content:'基本信息-请选择证件类型',
                        background:true
                    })
                    return
                }
                if(!data.userHomeDetail.idNum || data.userHomeDetail.idNum == ''){
                    this.$Message.warning({
                        content:'基本信息-证件号码不能为空',
                        background:true
                    })
                    return
                }else{
                    if(data.userHomeDetail.idNumType == '1'){
                        console.log(this.testid(data.userHomeDetail.idNum));
                        if(this.testid(data.userHomeDetail.idNum) == false){
                            this.$Message.warning({
                                content:'基本信息-请填写正确的内地身份证号',
                                background:true
                            })
                            return
                        }
                    }
                }
                if(!data.userHomeDetail.orgCode || data.userHomeDetail.orgCode.length != 3){   
                    this.$Message.warning({
                        content:'基本信息-请选择所属社区并选择到社区',
                        background:true
                    })
                    return
                }
                if(data.userHomeDetail.domicileOrgCode && data.userHomeDetail.domicileOrgCode.length > 0 && data.userHomeDetail.domicileOrgCode.length != 5){
                    this.$Message.warning({
                        content:'基本信息-户籍地址请选择到社区',
                        background:true
                    })
                    return
                }
               if(data.userHomeDetail.birthday != null){
                    data.userHomeDetail.birthday = this.$core.formatDate(new Date(data.userHomeDetail.birthday),"yyyy-MM-dd")
                    if(isNaN(data.userHomeDetail.birthday)&&!isNaN(Date.parse(data.userHomeDetail.birthday))){}else{this.formData.userHomeDetail.birthday = null}
               }
              

            }     
            //判断详细信息
            if(data.userHomeInfo){
               if(data.userHomeInfo.maritalDate != null){
                   data.userHomeInfo.maritalDate = this.$core.formatDate(new Date(data.userHomeInfo.maritalDate),"yyyy-MM-dd")
                    if(isNaN(data.userHomeInfo.maritalDate)&&!isNaN(Date.parse(data.userHomeInfo.maritalDate))){
        　              　
                        }else{
                         this.formData.userHomeInfo.maritalDate = null
                    }
               } 
            //    if(data.userHomeInfo.crime) {this.formData.userHomeInfo.crime = 1 } else if(data.userHomeInfo.crime == false) {this.formData.userHomeInfo.crime = 2} 
            //    if(data.userHomeInfo.emptyHouse) {this.formData.userHomeInfo.emptyHouse = 1 } else if(data.userHomeInfo.emptyHouse == false){this.formData.userHomeInfo.emptyHouse = 2} 
            //    if(data.userHomeInfo.household) {this.formData.userHomeInfo.household = 1 } else if(data.userHomeInfo.household == false){this.formData.userHomeInfo.household = 2} 
            //    if(data.userHomeInfo.migrants) {this.formData.userHomeInfo.migrants = 1 } else if(data.userHomeInfo.migrants == false){this.formData.userHomeInfo.migrants = 2} 
            //    if(data.userHomeInfo.psychosis) {this.formData.userHomeInfo.psychosis = 1 } else if(data.userHomeInfo.psychosis == false){this.formData.userHomeInfo.psychosis = 2} 
            //    if(data.userHomeInfo.retire) {this.formData.userHomeInfo.retire = 1 } else if(data.userHomeInfo.retire == false){this.formData.userHomeInfo.retire = 2} 
            //    if(data.userHomeInfo.tenant) {this.formData.userHomeInfo.tenant = 1 } else if(data.userHomeInfo.tenant == false){this.formData.userHomeInfo.tenant = 2}   


               if(data.userHomeInfo.household == 1) this.formData.userHomeInfo.householdRelation = ''
            }
            // 网上家园信息
            console.log(data.userHomeArchiveVos)
            // if(this.$route.query.isEdit == 'false'){
                if(data.userHomeArchiveVos){
                    for(let i =0;i<data.userHomeArchiveVos.length;i++){
                        if(!data.userHomeArchiveVos[i].houseCode){
                            this.$Message.warning({
                                content:'网上家园信息-请选现住地址',
                                background:true
                            })
                            return
                        }
                    }
                    // data.userHomeArchiveVos.map(e => {
                    //     if(!e.houseCode){
                    //         this.$Message.warning({
                    //             content:'网上家园信息-请选现住地址',
                    //             background:true
                    //         })
                    //         return
                    //     }
                    // })
                    // if(!data.userHomeArchiveVos.userType || data.userHomeArchiveVos.userType == ''){
                    //     this.$Message.warning({
                    //         content:'网上家园信息-请选择身份类型',
                    //         background:true
                    //     })
                    //     return
                    // }
                    // if(!data.userHomeArchiveVos.contact || data.userHomeArchiveVos.contact == ''){
                    //         this.$Message.warning({
                    //             content:'网上家园信息-请选择是否家庭户代表',
                    //             background:true
                    //         })
                    //         return
                    // }
                    
                }
            // }else{
                // if( Object.keys(data.userHomeArchive).length != 0){
                //     data.userHomeArchiveVos.forEach(e => {
                //         if(!e.houseCode){
                //            this.$Message.warning({
                //             content:'网上家园信息-请选现住地址',
                //             background:true
                //         }) 
                //         }
                //     })
                // }
            // }
            

             // 党员信息
   
            if(data.archiveCommunistRo){
                if(data.archiveCommunistRo.joinDate != null){data.archiveCommunistRo.joinDate = this.$core.formatDate(new Date(data.archiveCommunistRo.joinDate),"yyyy-MM-dd")}
                if(data.archiveCommunistRo.leaveDate != null){data.archiveCommunistRo.leaveDate = this.$core.formatDate(new Date(data.archiveCommunistRo.leaveDate),"yyyy-MM-dd")}
                if(data.archiveCommunistRo.memberBecome != null){data.archiveCommunistRo.memberBecome = this.$core.formatDate(new Date(data.archiveCommunistRo.memberBecome),"yyyy-MM-dd")}
                if(data.archiveCommunistRo.memberDate != null){data.archiveCommunistRo.memberDate = this.$core.formatDate(new Date(data.archiveCommunistRo.memberDate),"yyyy-MM-dd")}
               if(isNaN(data.archiveCommunistRo.joinDate)&&!isNaN(Date.parse(data.archiveCommunistRo.joinDate))){} else{
                  this.formData.archiveCommunistRo.joinDate = null
               }
               if(isNaN(data.archiveCommunistRo.leaveDate)&&!isNaN(Date.parse(data.archiveCommunistRo.leaveDate))){}else{
                   this.formData.archiveCommunistRo.leaveDate = null
               }
               if(isNaN(data.archiveCommunistRo.memberBecome)&&!isNaN(Date.parse(data.archiveCommunistRo.memberBecome))){}else{
                   this.formData.archiveCommunistRo.memberBecome = null
               }
               if(isNaN(data.archiveCommunistRo.memberDate)&&!isNaN(Date.parse(data.archiveCommunistRo.memberDate))){}else{
                 this.formData.archiveCommunistRo.memberDate = null
               }
           }
    
            
            // 志愿者
        
             if(data.archiveVolunteerRo){
                 if(data.archiveVolunteerRo.joinDate != null){
                     data.archiveVolunteerRo.joinDate = this.$core.formatDate(new Date(data.archiveVolunteerRo.joinDate),"yyyy-MM-dd")
                    if(isNaN(data.archiveVolunteerRo.joinDate)&&!isNaN(Date.parse(data.archiveVolunteerRo.joinDate))){}else{
                       this.formData.archiveVolunteerRo.joinDate = null
                     }
                 }
                 
             }
             
            // 健康需求评估
            if(data.userHealthRo){
                console.log(data.userHealthRo);
                if(data.userHealthRo.healthStatus){
                    if(data.userHealthRo.healthStatus == '3'){
                        console.log('选了');
                        if(!data.userHealthRo.diseaseDesc || data.userHealthRo.diseaseDesc == ''){
                            this.$Message.warning({
                                content:'健康需求评估-请填写疾病状况',
                                background:true
                            })
                            return
                        }
                    }
                }
                if(data.userHealthRo.selfCare){
                    if( data.userHealthRo.selfCare == '5'){
                        console.log('也选了');
                         if(!data.userHealthRo.selfCareDesc || data.userHealthRo.selfCareDesc == ''){
                            this.$Message.warning({
                                content:'健康需求评估-请填写其他状况',
                                background:true
                            })
                            return
                        }  
                    }
                }
            }
            // 特殊困难群体
            if(data.archiveHardRo){
                if(this.checkOther == true){
                    if(!data.archiveHardRo.orgCodeOther || data.archiveHardRo.orgCodeOther == ''){
                        this.$Message.warning({
                                content:'特殊困难群体信息采集-请填写所属其他',
                                background:true
                            })
                            return
                    }
                }
                if(data.archiveHardRo.hardType && data.archiveHardRo.hardType == '8'){
                    if(!data.archiveHardRo.hardTypeOther || data.archiveHardRo.hardTypeOther == ''){
                        this.$Message.warning({
                                content:'特殊困难群体信息采集-请填写其他分类',
                                background:true
                            })
                            return
                    }

                }
                if(data.archiveHardRo.socialService && data.archiveHardRo.socialService == 2){
                    if(!data.archiveHardRo.socialServiceDesc || data.archiveHardRo.socialServiceDesc == ''){
                        this.$Message.warning({
                                content:'特殊困难群体信息采集-请填写曾有社会服务记录',
                                background:true
                            })
                            return
                    }
                }
            }
            // 低保申请信息
            if(data.archiveBasiclivingRo){
                 if(data.archiveBasiclivingRo.approveDate != null){
                      data.archiveBasiclivingRo.approveDate =this.$core.formatDate(new Date(data.archiveBasiclivingRo.approveDate),"yyyy-MM-dd")
                 }
                 if(data.archiveBasiclivingRo.endDate != null){
                      data.archiveBasiclivingRo.endDate =this.$core.formatDate(new Date(data.archiveBasiclivingRo.endDate),"yyyy-MM-dd")
                 }
               
                
                if(isNaN( data.archiveBasiclivingRo.approveDate)&&!isNaN(Date.parse( data.archiveBasiclivingRo.approveDate))){}else{
                    this.formData.archiveBasiclivingRo.approveDate = null
                }
                if(isNaN( data.archiveBasiclivingRo.endDate)&&!isNaN(Date.parse( data.archiveBasiclivingRo.endDate))){}else{
                    this.formData.archiveBasiclivingRo.endDate = null
                }
             }
            //孤独老人信息

            if(data.archiveLonelyRo){
                if(data.archiveLonelyRo.safeService && data.archiveLonelyRo.safeService == '1'){
                    if(!data.archiveLonelyRo.company || data.archiveLonelyRo.company == ''){
                        this.$Message.warning({
                            content:'孤独老人信息-服务公司不能为空',
                            background:true
                        })
                        return
                    }
                }
                if(data.archiveLonelyRo.fireStatus && data.archiveLonelyRo.fireStatus == '2'){
                    if(!data.archiveLonelyRo.fireDesc || data.archiveLonelyRo.fireDesc == ''){
                        this.$Message.warning({
                            content:'孤独老人信息-消防问题描述不能为空',
                            background:true
                        })
                        return
                    }
                }
            }
            // 残疾人信息
          
            // 退役军职信息
   
            // 疫情防控档案信息
            // 核酸结果
       
            if(data.archiveNatRoList && data.archiveNatRoList.length != 0){
                
                
                let isTrue = false
                data.archiveNatRoList.map(item=>{
                    if(item.natDate == '' && item.natResult != ''){
                        isTrue  = true
                    }
                    if(item.natDate != '' && item.natResult == ''){
                        isTrue  = true
                    }
                })
                if(isTrue == true){
                    this.$Message.warning({
                            content:'疫情防控档案信息请填写完整',
                            background:true
                        })
                        return
                }

                let ismintwo = false
                data.archiveNatRoList.map(item=>{
                    if(item.natDate != '' || item.natResult != ''){
                        ismintwo = true
                    }
                })
               if(ismintwo == true){
                   if(!data.archiveVaccinationRo || data.archiveVaccinationRo.finishStatus == ''){
                       this.$Message.warning({
                            content:'疫情防控档案信息请选择疫苗接种情况',
                            background:true
                        })
                        return
                   }
               }
                
            }
      
            if(data.vaccinationDetailRoList && data.vaccinationDetailRoList.length != 0){
                let isFalse = false
                data.vaccinationDetailRoList.map(item=>{
                     if(item.vaccinationDate=='' && item.brand != ''){
                        isFalse = true
                    }
                     if(item.vaccinationDate!='' && item.brand == ''){
                        isFalse = true
                    }
                })
                if(isFalse == true){
                    this.$Message.warning({
                            content:'疫情防控档案信息请填写完整',
                            background:true
                        })
                        return
                }
                let ismin = false
                data.vaccinationDetailRoList.map(item=>{
                    if(item.vaccinationDate !='' || item.brand != ''){
                        ismin = true
                    }
                })
               if(ismin == true){
                   if(!data.archiveVaccinationRo || data.archiveVaccinationRo.finishStatus == ''){
                       this.$Message.warning({
                            content:'疫情防控档案信息请选择疫苗接种情况',
                            background:true
                        })
                        return
                   }
               }
                
            }

        
           


            console.log('最终',this.formData);
            if(this.$route.query.isEdit == 'false'){
                // 新增
                 this.addJson()
           
            }else{
                // 修改
                this.updateJson()
            }
        },
        // isName
        isName(name){
            if(name == '1') return this.showMenu = 'Base'
            if(name == '2') return this.showMenu = 'Personal'
            if(name == '3') return this.showMenu = 'Home'
            // if(name == '4') return this.showMenu = 'Family'
            if(name == '4') return this.showMenu = 'Party'
            if(name == '5') return this.showMenu = 'Volunteer'
            if(name == '6') return this.showMenu = 'Resident'
            if(name == '7') return this.showMenu = 'Healthy'
            if(name == '8') return this.showMenu = 'Special'
            if(name == '9') return this.showMenu = 'Allowance'
            if(name == '10') return this.showMenu = 'Lonely'
            if(name == '11') return this.showMenu = 'Disability'
            if(name == '12') return this.showMenu = 'Retire'
            if(name == '13') return this.showMenu = 'Vaccines'
        },
        changeOpenTips(status){
            this.openTips = status
        },

        // 基础表格的事件
        changeName(val){
                this.headerData[0].name = val     
        },
        // 接口部分
        // 新增接口,以及新增之前处理的数据
        addJson(){
            this.saveLoading = true
            let data = {}
            data = JSON.parse(JSON.stringify(this.formData));
            data.userHomeDetail.orgCode = data.userHomeDetail.orgCode[data.userHomeDetail.orgCode.length - 1]
            data.userHomeDetail.domicileOrgCode ? data.userHomeDetail.domicileOrgCode = data.userHomeDetail.domicileOrgCode[data.userHomeDetail.domicileOrgCode.length - 1] :''
             if(data.archiveCommunistRo){
                if(data.archiveCommunistRo.joinDate == null) delete data.archiveCommunistRo.joinDate
                if(data.archiveCommunistRo.leaveDate == null) delete data.archiveCommunistRo.leaveDate
                if(data.archiveCommunistRo.memberBecome == null) delete data.archiveCommunistRo.memberBecome
                if(data.archiveCommunistRo.memberDate == null) delete data.archiveCommunistRo.memberDate
                 if(data.archiveCommunistRo.joinService && data.archiveCommunistRo.joinService != null){if(data.archiveCommunistRo.joinService == true){data.archiveCommunistRo.joinService = 1}else{data.archiveCommunistRo.joinService = 2}}
            }   
            if(data.archiveBasiclivingRo){
                if(data.archiveBasiclivingRo.approveDate == null) delete data.archiveBasiclivingRo.approveDate
                if(data.archiveBasiclivingRo.endDate == null) delete data.archiveBasiclivingRo.endDate
            }
            // if(data.archiveCommunistRo.joinService ){if(data.archiveCommunistRo.joinService == true){data.archiveCommunistRo.joinService = 1}else{data.archiveCommunistRo.joinService = 2}}
            if(this.checkList.partyCheck   == false ) data.archiveCommunistRo = {}
            if(this.checkList.volunCheck   == false ) data.archiveVolunteerRo = {}
            if(this.checkList.residentCheck== false ) data.userRegionGroupRo = {}
            if(this.checkList.heathyCheck  == false ) data.userHealthRo = {}
            if(this.checkList.specialCheck == false ) data.archiveHardRo = {}
            if(this.checkList.allowCheck   == false ) data.archiveBasiclivingRo = {}
            if(this.checkList.alloneCheck  == false ) data.archiveLonelyRo = {}
            if(this.checkList.disabliCheck == false ) data.archiveDisabledRo = {}
            if(this.checkList.retireCheck  == false ) data.archiveRetirementRo = {}



            let arr = []
            if(data.archiveNatRoList && data.archiveNatRoList.length != 0){
                for(let i = 0; i<=data.archiveNatRoList.length - 1 ; i++){
                    if(data.archiveNatRoList[i].natDate != '' && data.archiveNatRoList[i].natResult !=''){
                        arr.push(data.archiveNatRoList[i])
                    }
                }
            }
            if(arr.length != 0){
                arr.map(item=>{
                    item.natDate = this.$core.formatDate(new Date(item.natDate),"yyyy-MM-dd")
                })
            }
            data.archiveNatRoList = arr
            let arr2 = []
            if(data.vaccinationDetailRoList && data.vaccinationDetailRoList.length != 0){
                for(let i = 0; i<=data.vaccinationDetailRoList.length - 1 ; i++){
                    if(data.vaccinationDetailRoList[i].vaccinationDate != '' && data.vaccinationDetailRoList[i].brand !=''){
                        arr2.push(data.vaccinationDetailRoList[i])
                    }
                }
            }
            if(arr2.length != 0 ){
                arr2.map(item =>{
                    item.vaccinationDate = this.$core.formatDate(new Date(item.vaccinationDate),"yyyy-MM-dd")
                })
            }
            data.vaccinationDetailRoList = arr2

            if(Object.keys(data.userHomeInfo).length == 0) {delete data.userHomeInfo}
            if(Object.keys(data.userHomeArchiveVos).length == 0) {delete data.userHomeArchiveVos}
            if(Object.keys(data.archiveCommunistRo).length == 0) {delete data.archiveCommunistRo}
            if(Object.keys(data.archiveVolunteerRo).length == 0) {delete data.archiveVolunteerRo}
            if(Object.keys(data.userRegionGroupRo).length == 0) {delete data.userRegionGroupRo}
            if(Object.keys(data.userHealthRo).length == 0) {delete data.userHealthRo}
            if(Object.keys(data.archiveHardRo).length == 0) {delete data.archiveHardRo}
            if(Object.keys(data.archiveBasiclivingRo).length == 0) {delete data.archiveBasiclivingRo}
            if(Object.keys(data.archiveLonelyRo).length == 0) {delete data.archiveLonelyRo}
            if(Object.keys(data.archiveDisabledRo).length == 0) {delete data.archiveDisabledRo}
            if(Object.keys(data.archiveRetirementRo).length == 0) {delete data.archiveRetirementRo}
            if(Object.keys(data.archiveVaccinationRo).length == 0) {delete data.archiveVaccinationRo}
            if(data.archiveHardItemRoList.length == 0) {delete data.archiveHardItemRoList}
            if(data.archiveNatRoList.length == 0) {delete data.archiveNatRoList}
            if(data.vaccinationDetailRoList.length == 0) {delete data.vaccinationDetailRoList}

            this.$post('/syaa/api/syuser/pc/residentArchive/saveResidentArchive',{
                ...data,
                orgCode:data.userHomeDetail.orgCode
            },{"Content-Type": "application/json"}).then(res=>{
                this.saveLoading = false
                if(res.code == 200){ 
                    if(res.data.isSave == true){
                        this.$Message.success({
                        content:'操作成功',
                        background:true
                        })
                        // if(data.userHomeDetail.houseCode && data.userHomeDetail.houseCode != ''){
                            if(this.$route.query.build)
                            this.$router.push(`/regionaloverview?isLineHome=${this.$route.query.isLineHome}&nameover=${this.$route.query.nameover}&communityCode=${this.$route.query.communityCode}`)
                            else if(this.$route.query.grid)
                            this.$router.push(`/gridindex?isLineHome=${this.$route.query.isLineHome}`)
                            else
                            this.$router.push(`/linehomeindex?isLineHome=${this.$route.query.isLineHome}&page=${this.$route.query.page}`)
                        // }else{
                        //     this.$router.push('/supplement')
                        // }
                    }else{
                        this.tipIdNum = data.userHomeDetail.idNum
                        this.changeOpenTips(true)
                        this.addFailed = res.data
                    }
                    
                }
                else{
                    this.$Message.error({
                        content:'保存失败',
                        background:true
                    })
                }
                
            })
        },

        // 修改接口,以及修改前处理的数据
        updateJson(){
            this.saveLoading = true
            let data = {}
            data = JSON.parse(JSON.stringify(this.formData));
            
            // for(let key in data.userHomeInfo){
            //     if(data.userHomeInfo[key] == 'true')
            //     data.userHomeInfo[key] == '1'
            //     if(data.userHomeInfo[key] == 'false')
            //     data.userHomeInfo[key] == '2'
            // }


            data.userHomeDetail.orgCode = data.userHomeDetail.orgCode[data.userHomeDetail.orgCode.length - 1]
            data.userHomeDetail.domicileOrgCode ? data.userHomeDetail.domicileOrgCode = data.userHomeDetail.domicileOrgCode[data.userHomeDetail.domicileOrgCode.length - 1] :''

            if(data.archiveCommunistRo){
                if(data.archiveCommunistRo.joinDate == null) delete data.archiveCommunistRo.joinDate
                if(data.archiveCommunistRo.leaveDate == null) delete data.archiveCommunistRo.leaveDate
                if(data.archiveCommunistRo.memberBecome == null) delete data.archiveCommunistRo.memberBecome
                if(data.archiveCommunistRo.memberDate == null) delete data.archiveCommunistRo.memberDate

                console.log('修改的joinService',data.archiveCommunistRo.joinService);
                 if(data.archiveCommunistRo.joinService && data.archiveCommunistRo.joinService != null){if(data.archiveCommunistRo.joinService == true){data.archiveCommunistRo.joinService = 1}else{data.archiveCommunistRo.joinService = 2}}

                 if(data.archiveCommunistRo.joinService == false){
                     data.archiveCommunistRo.joinService = 2
                 }
                
            }        
            if(data.archiveBasiclivingRo){
                if(data.archiveBasiclivingRo.approveDate == null) delete data.archiveBasiclivingRo.approveDate
                if(data.archiveBasiclivingRo.endDate == null) delete data.archiveBasiclivingRo.endDate
            }
            if(this.checkList.partyCheck   == false ) data.archiveCommunistRo = {}
            if(this.checkList.volunCheck   == false ) data.archiveVolunteerRo = {}
            if(this.checkList.residentCheck== false ) data.userRegionGroupRo = {}
            if(this.checkList.heathyCheck  == false ) data.userHealthRo = {}
            if(this.checkList.specialCheck == false ) data.archiveHardRo = {}
            if(this.checkList.allowCheck   == false ) data.archiveBasiclivingRo = {}
            if(this.checkList.alloneCheck  == false ) data.archiveLonelyRo = {}
            if(this.checkList.disabliCheck == false ) data.archiveDisabledRo = {}
            if(this.checkList.retireCheck  == false ) data.archiveRetirementRo = {}
            delete data.userHomeDetail.changeDomicArr
            delete data.userHomeDetail.changeOrgArr
            if(data.userHomeDetail.domicileOrgPath) data.userHomeDetail.domicileOrgPath = data.userHomeDetail.domicileOrgPath.join(',')
            if(data.userHomeDetail.orgPath)  data.userHomeDetail.orgPath = data.userHomeDetail.orgPath.join(',')
            


            if(Object.keys(data.userHomeInfo).length == 0) {delete data.userHomeInfo}
            if(Object.keys(data.userHomeArchiveVos).length == 0) {delete data.userHomeArchiveVos}
            if(Object.keys(data.archiveCommunistRo).length == 0) {delete data.archiveCommunistRo}
            if(Object.keys(data.archiveVolunteerRo).length == 0) {delete data.archiveVolunteerRo}
            if(Object.keys(data.userRegionGroupRo).length == 0) {delete data.userRegionGroupRo}
            if(Object.keys(data.userHealthRo).length == 0) {delete data.userHealthRo}
            if(Object.keys(data.archiveHardRo).length == 0) {delete data.archiveHardRo}
            if(Object.keys(data.archiveBasiclivingRo).length == 0) {delete data.archiveBasiclivingRo}
            if(Object.keys(data.archiveLonelyRo).length == 0) {delete data.archiveLonelyRo}
            if(Object.keys(data.archiveDisabledRo).length == 0) {delete data.archiveDisabledRo}
            if(Object.keys(data.archiveRetirementRo).length == 0) {delete data.archiveRetirementRo}
            if(Object.keys(data.archiveVaccinationRo).length == 0) {delete data.archiveVaccinationRo}
            if(data.archiveHardItemRoList.length == 0) {delete data.archiveHardItemRoList}
            if(data.archiveNatRoList.length == 0) {delete data.archiveNatRoList}
            if(data.vaccinationDetailRoList.length == 0) {delete data.vaccinationDetailRoList}
        
             let arr = []
            if(data.archiveNatRoList && data.archiveNatRoList.length != 0){
                for(let i = 0; i<=data.archiveNatRoList.length - 1 ; i++){
                    if(data.archiveNatRoList[i].natDate != '' && data.archiveNatRoList[i].natResult !=''){
                        arr.push(data.archiveNatRoList[i])
                    }
                }
            }
            if(arr.length != 0){
                arr.map(item=>{
                    item.natDate = this.$core.formatDate(new Date(item.natDate),"yyyy-MM-dd")
                })
            }
            data.archiveNatRoList = arr
            let arr2 = []
            if(data.vaccinationDetailRoList && data.vaccinationDetailRoList.length != 0){
                for(let i = 0; i<=data.vaccinationDetailRoList.length - 1 ; i++){
                    if(data.vaccinationDetailRoList[i].vaccinationDate != '' && data.vaccinationDetailRoList[i].brand !=''){
                        arr2.push(data.vaccinationDetailRoList[i])
                    }
                }
            }
            if(arr2.length != 0 ){
                arr2.map(item =>{
                    item.vaccinationDate = this.$core.formatDate(new Date(item.vaccinationDate),"yyyy-MM-dd")
                })
            }
            data.vaccinationDetailRoList = arr2

            this.$post('/syaa/api/syuser/pc/residentArchive/saveResidentArchive',{
                ...data,
                familyId:this.$route.query.familyId,
                familyMemberId:this.$route.query.familyMemberId,
                custGlobalId:this.$route.query.custGlobalId,
                orgCode:this.$route.query.orgCode
            },{"Content-Type": "application/json"}).then(res=>{
                this.saveLoading = false
                if(res.code == 200){ 
                    if(res.data.isSave == true){
                        this.$Message.success({
                        content:'修改成功',
                        background:true
                        })
                            if(this.$route.query.build)
                            this.$router.push(`/regionaloverview?isLineHome=${this.$route.query.isLineHome}&nameover=${this.$route.query.nameover}&communityCode=${this.$route.query.communityCode}`)
                            else if(this.$route.query.grid)
                            this.$router.push(`/gridindex?isLineHome=${this.$route.query.isLineHome}`)
                            else
                            this.$router.push(`/linehomeindex?isLineHome=${this.$route.query.isLineHome}&page=${this.$route.query.page}`)
                    }else{
                        this.tipIdNum = data.userHomeDetail.idNum
                    this.changeOpenTips(true)
                    this.addFailed = res.data
                    }
                    
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                }
            })
        },
        // 重置身份证
        aginText(){
        
            this.formData.userHomeDetail.idNum = ''
            this.changeOpenTips(false)
        },
        //去查看
        goLook(){
            console.log(this.addFailed)
            // this.changeClose(false)
            this.$router.push(`/blank?userId=${this.addFailed.userId}&custGlobalId=${this.addFailed.custGlobalId}&familyId=${this.addFailed.familyId}&familyMemberId=${this.addFailed.familyMemberId}&orgCode=${this.addFailed.orgCode}&isLineHome=${this.$route.query.isLineHome}&page=${this.$route.query.page}`)
            // this.alterDetails(this.addFailed.userId,this.addFailed.custGlobalId,this.addFailed.familyId,this.addFailed.familyMemberId,this.addFailed.orgCode,)
        },
        alterDetails(userId,custGlobalId,familyId,familyMemberId,orgCode){
            this.$router.push(`/linehomeaddform?isEdit=true&userId=${userId}&custGlobalId=${custGlobalId}&familyId=${familyId}&familyMemberId=${familyMemberId}&orgCode=${orgCode}`);
        },
        // 获取头部数据
        getHeader(){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectUserIndexInfo',{
                userId:this.$route.query.userId,
                operCustGlobalName:parent.vue.loginInfo.userinfo.realName
            }).then(res=>{
                if(res.code == 200){
                    let data = res.data
                    this.$nextTick(()=>{
                        if(data.basicLiving == '1') this.headerData[0].allowance = true,this.checkList.allowCheck = true//享受低保
                        if(data.communist == '1')this.headerData[0].party = true,this.checkList.partyCheck = true//是否党员
                        if(data.disabled == '1')  this.headerData[0].disability = true,this.checkList.disabliCheck = true//是否残疾人
                        if(data.hard == '1')this.headerData[0].special = true,this.checkList.specialCheck = true //是否困难群体
                        if(data.healthAssessment == '1') this.headerData[0].healthy = true,this.checkList.heathyCheck = true//是否进行健康评估
                        if(data.lonelyMan == '1')this.headerData[0].lonely = true,this.checkList.alloneCheck = true //是否孤独老人
                        if(data.regionGroup == '1')this.headerData[0].resident = true,this.checkList.residentCheck = true//是否居民小组人员
                        if(data.veteran == '1')this.headerData[0].retire = true,this.checkList.retireCheck = true //是否退伍军人
                        if(data.volunteer == '1') this.headerData[0].volunteer = true,this.checkList.volunCheck = true//是否志愿者  
                        if(data.orgPath && data.orgPath != '') this.allowOrgCode = data.orgPath
                    })
                   
                }
            })
        },
         //公共方法
    // 校验身份证
    testid(id) {
    // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
        var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
        //号码规则校验
        if(!format.test(id)){
        return false
        }
        //区位码校验
        //出生年月日校验  前正则限制起始年份为1900;
        var year = id.substr(6,4),//身份证年
        month = id.substr(10,2),//身份证月
        date = id.substr(12,2),//身份证日
        time = Date.parse(month+'-'+date+'-'+year),//身份证日期时间戳date
        now_time = Date.parse(new Date()),//当前时间戳
        dates = (new Date(year,month,0)).getDate();//身份证当月天数
        if(time>now_time||date>dates){
        return false
        }
        //校验码判断
        var c = new Array(7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2);  //系数
        var b = new Array('1','0','X','9','8','7','6','5','4','3','2'); //校验码对照表
        var id_array = id.split("");
        var sum = 0;
        for(var k=0;k<17;k++){
        sum+=parseInt(id_array[k])*parseInt(c[k]);
        }
        if(id_array[17].toUpperCase() != b[sum%11].toUpperCase()){
        return false
        }
        return true
        }
    }
}
</script>

<style lang="less" scoped>
.add-container{
    width: 100%;
    height: 100%;
    // border: 1px solid red;
    padding: 20px;
    .add-top{
        width:100%;
        .top-header{
            display: flex;
            .table{
                flex: 1;
            }
            .btn-div{
                margin-left: auto;
                padding-left: 20px;
            }
        }
    }
    .add-bottom{
        background: #ffffff;
        margin-top: 20px;
        width: 100%;
        height: 700px;
        border: 1px solid #cccccc;
        display: flex;
        justify-content: flex-start;
        .bottom-menu{
            border-left: 1px solid #cccccc;
            position: relative;
        }
        .bottom-container{
            height: 100%;    
            flex: 1;
            overflow-y: scroll;
            padding: 20px;
        }
        .bottom-right{
            width: 20%;
            margin-left: auto;
            border-left: 1px solid #cccccc;
            height: 100%;
            .btn-div{
                width: 200px;
                height: 300px;
                margin: 0 auto;
                margin-top: 240px;
                div{
                    text-align: center;
                    margin: 30px 0;
                    user-select: none;
                }
            }
        }
    }
}
/deep/.ivu-menu{
    position: static;
    
}
</style>